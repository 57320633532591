<script src="./Book.js"></script>

<svelte:head>
    {#if book}
      {#if book.id == "minecraft:written_book"}
        <title>{book.title.value} | by {book.author.value}</title>
      {:else}
        {#if pages.length > 0}
          <title>{firstPage} | by Unknown Author</title>
        {/if}
      {/if}
    {/if}

    <link rel="preload" as="image" href="../assets/arrowleftred.png">
    <link rel="preload" as="image" href="../assets/arrowrightred.png">
    <link rel="preload" as="image" href="../assets/arrowleft.png">
    <link rel="preload" as="image" href="../assets/arrowright.png">
</svelte:head>

{#if book}
<div class="vertCenter">

  {#if book.id == "minecraft:written_book"}
    <h1>{book.title.value}</h1>
    <p>by {book.author.value}</p>
  {:else}
    <h1>Book and Quill</h1>
    <p class="authorSize" >{secretPoetsName}</p>
  {/if}



    <Group>
{#each pages as page, i}

    <div class="book" style="display: {((i == currentPage) ? "block" : "none")}" >
        <div class="textField">
            <p class="pageNumber">Page {i+1} of {pages.length}</p>
            <p class="bookContent">{@html page}</p>
        
            {#if currentPage != 0}
                <button on:click={downPage} class="leftArrow arrow" ></button>
            {/if}

            {#if currentPage +1 != pages.length }
                <button  on:dblclick={removeSelection} on:click={upPage} class="rightArrow arrow" ></button>
            {/if}
        </div>
    </div>
    {/each}
</Group>

</div>
 

<div class="occurences">

  {#each book.foundWhere as occur }
    <div class="occurence">
      <Group>
        {#if occur.uuid}
          <img src="assets/blocks/steve.png" style="width: 32px" alt="steve">
          <div>
            <p style="margin: 3px;">{occur.uuid}</p>
            {#if occur.insideEndyChest}
              <p style="margin: 0px; font-size: 12px;">Inside Ender Chest</p>
            {:else}
              <p style="margin: 0px; font-size: 12px;">Inside Inventory.</p>
            {/if}
          </div>
        {:else if occur.id}
          <img src={`assets/blocks/${occur.id.replace("minecraft:", "") + ".png"}`} style="width: 32px" alt="{occur.id}">
          <div>
            <Group css={{gap:"5px;"}}>
              <p style="margin: 3px;">{occur.id.replace("minecraft:", "")}</p>
            {#if occur.shulkyBox}
              <img style="width: 18px" src="assets/blocks/shulker_box.png" alt="Is inside shulker box.">
            {/if}
          </Group>

              <p style="margin: 0px; font-size: 12px;">{occur.x} {occur.y} {occur.z}</p>
          </div>
        {/if}
        
      </Group>
  
    </div>
  {/each}

</div>

{:else}
    <div class="repairChild vertCenter">
        <Progress color="red" radius="md" value={$glob.percentComplete}/>
        <Text>Loading books: {$glob.percentComplete}%</Text>
    </div>
{/if}

<style>
  .authorSize{
    max-width: 440px;
  }
.occurences{
  position: fixed;
  bottom: 18px;
  left: 0;
  min-width: 200px;
  width: fit-content;
  background-color: #f1f1f1; 
  padding: 10px; 
  background-color: rgb(15, 15, 15);
  max-height: 400px;
  overflow:scroll;
}

@media only screen and (max-width: 1250px) {
  .occurences{
    position: fixed;
  bottom: 0;
  left: 0;
  min-width: 200px;
  width: fit-content;
  background-color: #f1f1f1; 
  padding: 10px; 
  background-color: rgb(15, 15, 15);
  max-height: 200px;
  overflow:scroll;
  }
}

@media only screen and (max-height: 800px) and (max-width: 1250px)  {
  .occurences{
    position: fixed;
  bottom: 0;
  left: 0;
  min-width: 200px;
  width: fit-content;
  background-color: #f1f1f1; 
  padding: 10px; 
  background-color: rgb(15, 15, 15);
  max-height: 200px;
  overflow:scroll;
  }
}

@media only screen and (max-height: 700px) and (max-width: 1250px)  {
  .occurences{
    position: fixed;
  bottom: 0;
  left: 0;
  min-width: 200px;
  width: fit-content;
  background-color: #f1f1f1; 
  padding: 10px; 
  background-color: rgb(15, 15, 15);
  max-height: 130px;
  overflow:scroll;
  }
}

.leftArrow{
    all:unset;
    position: absolute;
    left: 80px;
    bottom: 45px;
    width: 54px;
    height: 30px;
    background: url("../assets/arrowleft.png");
    border:none;
}
.leftArrow:hover{

    background: url("../assets/arrowleftred.png")
}

.arrow{      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;}

.rightArrow{
    all:unset;

    position: absolute;
    right: 80px;
    bottom: 45px;
    width: 54px;
    height: 30px;
    background: url("../assets/arrowright.png");
    border:none;
}
.rightArrow:hover{
    background: url("../assets/arrowrightred.png")
}


 .vertCenter {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);

}

.book {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 10px;
}

.textField {
  width: 360px;
  word-break: break-word;
  background-image: url("../assets/mcbook.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
}

.bookContent {
  margin-left: 18px;
  margin-top: 51px;
  color: black !important;
  font-size: 22.5px;
  height: 390px;
  width: 330px;
  word-spacing: 6px;
  line-height: 22.5px;

}

.pageNumber {
  width: 337.5px;
  margin: 0px;
  position: absolute;
  color: black !important;
  font-size: 22.5px;
  font-family: Minecraft, Roboto Mono, Monospace;
  margin-top: 9px;
  text-align: right;
  word-spacing: 6px;
}

@media only screen and (max-width: 1250px) {
  .occurences{
  position: fixed;
  bottom: 34px;
  left: 0;
  min-width: 200px;
  width: fit-content;
  background-color: #f1f1f1; 
  padding: 10px; 
  background-color: rgb(15, 15, 15);
  max-height: 400px;
  overflow:scroll;
}

  .authorSize{
    max-width: 280px;
  }

    .leftArrow{
    all:unset;

    position: absolute;
    left: 70px;
    bottom: 33px;
    width: 37.8px;
    height: 21px;
    background: url("../assets/arrowleft.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.leftArrow:hover{
    background: url("../assets/arrowleftred.png");
    width: 37.8px;
    height: 21px;
    background-size: cover;
        background-repeat: no-repeat;
}

.rightArrow{
    all:unset;

    position: absolute;
    right: 70px;
    bottom: 33px;
    width: 37.8px;
    height: 21px;
    background: url("../assets/arrowright.png");

    background-size: cover;
        background-repeat: no-repeat;
}
.rightArrow:hover{
    background: url("../assets/arrowrightred.png");
    width: 37.8px;
    height: 21px;
    background-size: cover;
        background-repeat: no-repeat;
}


  .book {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 10px;
  }

  .textField {
    width: 240px;
    word-break: break-word;
    background-image: url("../assets/mcbook.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
  }

  .bookContent {
    margin-left: 12px;
    margin-top: 34px;
    color: black !important;
    font-size: 15px;
    height: 260px;
    width: 220px;
    word-spacing: 4px;
    line-height: 15px;
  }

  .pageNumber {
    width: 225px;
    margin: 0px;
    position: absolute;
    color: black !important;
    font-size: 15px;
    font-family: Minecraft, Roboto Mono, Monospace;
    margin-top: 6px;
    text-align: right;
    word-spacing: 4px;
  }
}
</style>

