<script>
  import { SvelteUIProvider } from "@svelteuidev/core";
  import Router from "svelte-spa-router";
  import Book from "./Routes/Book/Book.svelte";
  import Books from "./Routes/Books/Books.svelte";
  import Home from "./Routes/Home/Home.svelte";
  import { glob } from "./stores.js";



  let _books = localStorage.getItem("books");
  console.log(_books);
  if (_books) $glob.books = JSON.parse(_books);
  else loadBooks()

  loadMeta()

  const routes = {
    // Exact path
    "/": Home,
    "/books": Books,
    "/book/:bookID?": Book
  };

  async function loadMeta() {
    let crosscheck = localStorage.getItem("meta");
    if (crosscheck) crosscheck = JSON.parse(crosscheck);


    $glob.meta = {}
    $glob.meta = await (
      await fetch("meta.json", {
        method: "GET",
        credentials: "same-origin",
        mode: "cors",
      })
    ).json();

    if(crosscheck) if(crosscheck.lastChange != $glob.meta.lastChange) {
      console.log("Outdated.")
      localStorage.removeItem("books")
      location.reload()
    }

    localStorage.setItem("meta", JSON.stringify($glob.meta));
  }

  async function loadBooks() {
    $glob.percentComplete = 0;

    let xhr = new XMLHttpRequest();
    xhr.open("GET", "worldExport.json", true);
    xhr.onprogress = async function (event) {
      if (event.lengthComputable) {
        $glob.percentComplete = Math.round((event.loaded / event.total) * 100);
     }
    };

    xhr.onerror = async function () {
      alert("The request failed!");
    };

    xhr.onload = async function () {
      console.log("Ran onload.")
      if (xhr.status >= 200 && xhr.status < 400) {
        let responseData = xhr.response;
        $glob.books = structuredClone(await shuffle(JSON.parse(responseData)));
        console.log("Done")
        console.log($glob.books)
        localStorage.setItem("books", JSON.stringify($glob.books));
        $glob.percentComplete = -1
      } else {
        // Handle error response
        alert("Error:", xhr.status);
      }
    };

    xhr.send();
  }

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];

        // Swap
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
};

</script>

<svelte:head>
	<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Nunito Sans">
</svelte:head>

<main>
  <SvelteUIProvider themeObserver="dark">
    <Router {routes} restoreScrollState={true}/>
  </SvelteUIProvider>

  	<div>
  		<a id='foo' target="_blank" href="https://floppa.hair">made with McArchiver</a>
	</div>
</main>

<style global>
  :global(h1) {
    color: white !important;
  }
  :global(p) {
    color: white !important;
  }

  :global(html) {
    background-color: #242424;
  }
  :global(main) {
    height: 100%;
	font-family: "Nunito Sans" !important;
  image-rendering: pixelated !important;
  }

  #foo {
	position: fixed;
    bottom: 0;
    right: 0;
	color: lightblue;
	background-color: rgb(56, 56, 56);
	width: 100%;
	text-align: right;
	padding: 5px
  }
  
  :global(.svelteui-Button-root):active{
	background-color: purple;
  }
</style>
