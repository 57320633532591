<script src="./Home.js"></script>

<svelte:head>
  {#if $glob.meta.indexable == false}
    <meta name="robots" content="noindex">
  {/if}
  {#if $glob.meta.title}
    <title>Home | {$glob.meta.title}</title>
  {:else}
    <title>Home</title>
  {/if}
</svelte:head>

 <div class="vertCenter">
    <Center>
      <Image radius='md' width={64} src="favicon.png" alt='image of the server.' /><h1>{$glob.meta.title}</h1>
    </Center>
    <Container
      css={{
        backgroundColor: "#18191b",
        padding: "30px",
        borderRadius: "$md",
        maxWidth: "800px",
        marginBottom: "50px"
      }}
    >

      <Text css={{fontFamily:"Nunito Sans", marginBottom: "20px"}} color="white" size="xl">{$glob.meta.description}</Text>
      <Text css={{fontFamily:"Nunito Sans", marginBottom: "20px"}} color="white" size="lg">{$glob.meta.text}</Text>
      {#if $glob.books}
        <Center><a href="#/books"><Button radius="md" size="lg" ripple>Enter Archive</Button></a></Center>
      {:else}
        <Progress color="lime" radius="md" value={$glob.percentComplete}/>
        <Text >Loading books: {$glob.percentComplete}%</Text>
      {/if}
    </Container>
  </div>

  <style global>
    .vertCenter{
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  </style>