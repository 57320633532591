<script src="./Books.js"></script>
<svelte:head>

    {#if $glob.meta.title}
        <title>Books | {$glob.meta.title}</title>
    {:else}
        <title>Books</title>
    {/if}
</svelte:head>
<Text size="lg">Todo: Add a lot of search and sort options</Text>
<br>
<div class="vertCenter"> 
    <Group position="center">
        {#if books.length > 0}
            {#each books as book, i}

                <div class="book">
                    <a style="text-decoration: none;" href={`/#/book/${book?.uniqueID}`}>
                        <div class="textField">
                            <p class="pageNumber">Preview 1 of {book.pages.value.value.length}</p>
                            <p class="bookContent">{@html book.parsed[0]}</p>
                        </div>
                    </a>


                        <Group >
                            <div style="width: 205px;">
                                {#if book.id == "minecraft:written_book"}
                                    <a href={`/#/book/${book?.uniqueID}`}><Text size="lg">{book?.title?.value}</Text></a>
                                    <Text size="md" color="gray">by {book?.author?.value}</Text>
                                {:else}
                                    <a href={`/#/book/${book?.uniqueID}`}><Text size="lg">Book and Quill</Text></a>
                                    <Text size="md" color="gray">Author Unknown</Text>
                                {/if}
                            
                            </div>
                            {#if book.id == "minecraft:written_book"}
                                <img src="assets/writtenbook.webp" alt="Written book" class="bookImg">
                            {:else}
                                <img src="assets/bookandquill.webp" alt="Written book" class="bookImg">
                            {/if}
                        </Group>
                    </div>


                    {#if i == books.length - 1}
                        {scrollWhenRendered()}
                    {/if}
            {/each}
        {:else}
            <div class="repairChild vertCenter">
                <Progress color="red" radius="md" value={$glob.percentComplete}/>
                <Text >Loading books: {$glob.percentComplete}%</Text>
            </div>
        {/if}
    </Group> 
</div>
<style global>

.repairChild:first-child{
    width: 200px;
}

    .book{
        background-color: #1a1a1a;
        border-radius: 10px;
        padding: 10px
    }

    .bookImg{
        width: 48px;
        float:right;
    }

    .textField{
        width: 240px;
        word-break: break-word;
        background-image: url("../assets/mcbook.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px;
        user-select: none;
        cursor:pointer;
    }

    .bookContent{
        margin-left: 12px;
        margin-top: 34px;
        color: black !important;
        font-size: 15px;
        height: 260px;
        width: 220px;
        word-spacing: 4px;
        line-height: 15px;
    }

    .pageNumber{
        width: 225px;
        margin: 0px;
        position: absolute;
        color: black !important;
        font-size: 15px;
        font-family: Minecraft, Roboto Mono, Monospace;
        margin-top: 6px;
        text-align: right;
        word-spacing: 4px;
    }


</style>

<svelte:window bind:scrollY={scrollPos} />
   